@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  padding: 0;
  margin: 0;
}

body{
  background-color: #0ea5e9;
}

.App{
  display: flex;
  justify-content: center;
}

/* 
   =================== 
      Cv
   ===================
*/ 

h1{
  font-size: 2.5rem;
}

label{
  font-size: 1.5rem;

}



section{
  padding: 10px 0;
}

.btn-edit{
  padding: 10px 20px;
  
}